import React from "react";
import "./Cta.css";
import { CtaConfig } from "../lib/types/cmsConfigs";
import { ReactComponent as Arrow } from "../assets/Arrow.svg";

interface CtaProps {
  config: CtaConfig;
}

export const Cta = ({ config }: CtaProps) => {
  return (
    <div className="cta-container">
      <div className="cta-link-container">
        <div className="sticky-button">{config.cta}</div>
        <Arrow className="arrow-art" />
      </div>
    </div>
  );
};
