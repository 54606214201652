import { Stack } from "@mui/material";
import React from "react";
import "./AccordionDisplay.css";
import "./InformationUnavailableDisplay.css";
import { Link } from "../../common/Link";

type InformationUnavailableDisplayProps = {
  districtUrl?: string;
};

export const InformationUnavailableDisplay = ({
  districtUrl,
}: InformationUnavailableDisplayProps) => {
  return (
    <div className="information-unavailable-root">
      <Stack spacing={1} className="information-unavailable-container">
        <div className="district-section-titles accordion-subtitle">
          Information Unavailable at this time.
        </div>

        {districtUrl && (
          <div className="district-section-titles accordion-subtitle">
            Please visit the{" "}
            <Link
              href={districtUrl}
              style={{ color: "var(--primary-600-main)" }}
            >
              <span>District website</span>
            </Link>{" "}
            for the most up to date information.
          </div>
        )}
      </Stack>
    </div>
  );
};
