import React, { ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import "./AccordionDisplay.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EventType, trackEvent } from "../../lib/analytics";

interface AccordionDisplayProps {
  title: string;
  updated?: string;
  children?: ReactNode;
}

const AccordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
  },
  boxShadow: "none",
  margin: "0px",
  marginTop: "0px",
};

export const AccordionDisplay = (props: AccordionDisplayProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const { title, children } = props;

  const handleAccordionClick = () => {
    trackEvent(EventType.AccordionClick, { title, isOpening: !expanded });
    setExpanded(!expanded);
  };

  return (
    <Accordion
      sx={AccordionStyle}
      className="accordion-display"
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon style={{ color: "var(--primary-600-main)" }} />
        }
        onClick={handleAccordionClick}
      >
        <Stack direction="column" spacing={1}>
          <h3 className="accordion-title">{title}</h3>

          <div
            className="district-section-titles accordion-subtitle"
            style={{ color: "grey" }}
          >
            {/*<span>Last Updated: {updated || "N/A"}</span>*/}
          </div>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
