import React, { useEffect } from "react";
import { Cta } from "../common/Cta";
import { AddressLookupSection } from "./sections/AddressLookupSection";
import { StatisticsAndDataSection } from "./sections/StatisticsAndDataSection";
import { ResourcesSection } from "./sections/ResourcesSection";
import { useHomePageEndpoint } from "../hooks/wordpressCms";
import { Backdrop, CircularProgress } from "@mui/material";
import { Footer } from "../common/Footer";
// import { HookContentSection } from "./sections/HookContentSection";

function Home() {
  const { loadHomePage, homePageConfig } = useHomePageEndpoint();

  useEffect(() => {
    loadHomePage();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal }}
        open={!homePageConfig}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {homePageConfig && (
        <>
          <AddressLookupSection config={homePageConfig} />
          <Cta config={homePageConfig.cta} />
          <StatisticsAndDataSection config={homePageConfig} />
          <ResourcesSection config={homePageConfig} />
          <Footer config={homePageConfig.footer} />
        </>
      )}
    </div>
  );
}

export default Home;
