import React from "react";
import HtmlMapper from "react-html-map";
import "./HtmlRenderer.css";

type HtmlRendererProps = {
  html: string;
};

export const HtmlRenderer = ({ html }: HtmlRendererProps) => {
  return (
    <HtmlMapper html={html} decodeEntities={true}>
      {{
        b: ({ children, ...rest }) => (
          <strong className="strong" {...rest}>
            {children}
          </strong>
        ),
        strong: ({ children, ...rest }) => (
          <strong className="strong" {...rest}>
            {children}
          </strong>
        ),
        em: ({ children, ...rest }) => (
          <em className="italic" {...rest}>
            {children}
          </em>
        ),
        i: ({ children, ...rest }) => (
          <i className="italic" {...rest}>
            {children}
          </i>
        ),
        p: ({ children, ...rest }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { style, ...restProps } = rest;
          return <div {...restProps}>{children}</div>;
        },
        span: ({ children, ...rest }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { style, ...restProps } = rest;
          return <span {...restProps}>{children}</span>;
        },
      }}
    </HtmlMapper>
  );
};
