import React from "react";
import "./DistrictSection.css";
import { useSearchParams } from "react-router-dom";
import { AddressSearch } from "../../common/AddressSearch";
import { ResultPageConfig } from "../../lib/types/cmsConfigs";
import { EventType, trackEvent } from "../../lib/analytics";

type DistrictSectionProps = {
  config: Partial<ResultPageConfig>;
};

export const DistrictSection = ({ config }: DistrictSectionProps) => {
  const [searchParams] = useSearchParams();

  const handleChange = (formattedAddress: string) => {
    // setSearchParams(useSearchParams()[0]);
    trackEvent(EventType.DistrictChangeClick, {
      original_address: formattedAddress,
    });
  };

  return (
    <>
      <section className="district-card-body">
        <header className="district-card-header-title">
          <h3>{config.header?.headingText}</h3>
        </header>

        <div className="district-card-address-content">
          <AddressSearch
            findAddressDataTestId="change-address-search"
            errorMessagingConfig={config.errorMessaging}
            isResultsScreen={true}
            value={searchParams.get("address") as string}
            additionalOnClick={handleChange}
          />
        </div>
      </section>
    </>
  );
};
