import * as React from "react";
import { EventType, trackEvent } from "../lib/analytics";
import { default as MaterialLink } from "@mui/material/Link";

export const Link = ({
  href,
  children,
  className,
  style,
}: {
  href: string;
  children: React.ReactElement;
  className?: string;
  style?: any;
}) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    trackEvent(EventType.OutboundClick, { url: href });
    window.open(href, "_blank");
  };

  return (
    <MaterialLink
      href={href}
      target="_blank"
      rel="noreferrer"
      className={className}
      onClick={handleClick}
      style={style}
    >
      {children}
    </MaterialLink>
  );
};
