import React, { useState } from "react";
import { AccordionDisplay } from "./AccordionDisplay";
import { Popover, Stack } from "@mui/material";
import { IconButton } from "@mui/material";
import { BoardMember } from "../../lib/types/ballotReadyData";
import { InformationUnavailableDisplay } from "./InformationUnavailableDisplay";
import "./BoardMembersAccordion.css";
import { EventType, trackEvent } from "../../lib/analytics";
import { ReactComponent as Email } from "../../assets/Email.svg";

interface BoardMembersAccordionProps {
  boardMembers: BoardMember[] | [];
  boardMemberLastUpdatedAt?: string;
  districtUrl?: string;
}

export const BoardMembersAccordion = ({
  boardMemberLastUpdatedAt,
  boardMembers,
  districtUrl,
}: BoardMembersAccordionProps) => {
  let displayDateString = "";
  if (boardMemberLastUpdatedAt) {
    displayDateString = new Date(boardMemberLastUpdatedAt).toLocaleDateString(
      "en-US",
    );
  }

  if (boardMembers.length > 0) {
    return (
      <AccordionDisplay title="Board Members" updated={displayDateString}>
        <BoardMemberInfo boardMembers={boardMembers} />
      </AccordionDisplay>
    );
  }

  return (
    <AccordionDisplay title="Board Members">
      <InformationUnavailableDisplay districtUrl={districtUrl} />
    </AccordionDisplay>
  );
};

const BoardMemberInfo = ({ boardMembers }: { boardMembers: BoardMember[] }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleEmailIconClicked = (
    event: React.MouseEvent<HTMLElement>,
    email: string,
  ) => {
    trackEvent(EventType.CopyEmailClick, { person: "BoardMember", email });
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTimeout(() => setAnchorEl(null), 1000);
    navigator.clipboard.writeText(email);
  };

  return (
    <div>
      {boardMembers.map((member) => {
        return (
          <Stack
            direction="column"
            spacing={1}
            className="accordion-stack"
            key={member.fullName}
          >
            <div className="district-section-titles">{member.title}</div>
            <Stack direction="row" spacing={1} className="board-member">
              <h4>{member.fullName}</h4>
              {member.email && (
                <>
                  <IconButton
                    id="email-icon"
                    data-testid="email-icon"
                    sx={{ padding: "0px" }}
                    onClick={(event) =>
                      handleEmailIconClicked(event, member.email as string)
                    }
                  >
                    <Email />
                  </IconButton>
                  <Popover
                    data-testid="email-copied-popover"
                    classes={{ paper: "MuiPopover-paper" }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={!!anchorEl}
                  >
                    Email Copied to Clipboard
                  </Popover>
                </>
              )}
            </Stack>
          </Stack>
        );
      })}
    </div>
  );
};
