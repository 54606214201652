import { createTheme } from "@mui/material/styles";
export const Theme = createTheme({
  palette: {
    primary: {
      main: "#145758",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              display: "flex",
              padding: "15px 25px",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
              border: "1px solid",
              borderRadius: "50px",
            },
            "&.Mui-focused fieldset": {
              border: "1px solid #000",
              borderColor: "#000000",
            },
            "&.Mui-error .Mui-focused": {
              borderColor: "red",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          position: "relative",
          textAlign: "left",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "18px",
          transform: "none",
          marginBottom: "8px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            display: "flex",
            padding: "15px 25px",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
            border: "1px solid",
            borderRadius: "50px",
          },
        },
        input: {
          "&.mui-focused fieldset:focus": {
            border: "1px solid #000",
          },
        },
      },
    },
  },
});
