import React from "react";
import { Box, IconButton } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { ReactComponent as Logo } from "../assets/XQ_FooterLogo.svg";
import { FooterConfig } from "../lib/types/cmsConfigs";
import "./Footer.css";

interface FooterProps {
  config: FooterConfig;
}

export const Footer = ({ config }: FooterProps) => {
  return (
    <Box className="footer" role="contentinfo">
      <div className="footer-container">
        <div className="footer-section-left">
          <Logo className="footer-logo" />
        </div>

        <div className="footer-section-right">
          <div className="right-contact-container">
            <span className="right-header-contact">
              {config.contactHeaderText}
            </span>
            <p className="right-contact-links">
              <a href={config.contactLinkUrl1}>{config.contactLinkText1}</a>{" "}
              <span> | </span>
              <a href={config.contactLinkUrl2}>
                {config.contactLinkText2}{" "}
              </a>{" "}
              <span> | </span>
              <a href={config.contactLinkUrl3}>{config.contactLinkText3}</a>
            </p>
          </div>

          <div className="right-socials-container">
            <span className="right-header-socials">
              {config.socialHeaderText}
            </span>
            <div className="right-social-icons">
              <IconButton href={config.socialFacebookUrl} target="_blank">
                <FacebookOutlinedIcon fontSize="medium" />
              </IconButton>
              <IconButton href={config.socialTwitterUrl} target="_blank">
                <TwitterIcon fontSize="medium" />
              </IconButton>
              <IconButton href={config.socialInstagramUrl} target="_blank">
                <InstagramIcon fontSize="medium" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-legal">
        <p>
          {" "}
          {config.legalHeaderText} <span> | </span>
          <a href={config.legalLinkUrl1}>{config.legalLinkText1}</a>
          <span> | </span>
          <a href={config.legalLinkUrl2}>{config.legalLinkText2}</a>
        </p>
      </div>
    </Box>
  );
};
