export const getApplicationConfiguration = () => {
  return `
    query GetApplicationPage {
      page(id: "/home", idType: URI) {
        resultPageHeader {
          resultHeaderText
          resultHeaderInputLabel
        }

        homePageHeader {
          homePageHeaderHeading
          homePageHeaderInputLabel
          homePageHeaderHeroImage {
            node {
              sourceUrl
            }
          }
        }

        sharedCta {
          sharedCta
        }

        homePageHookContent {
          homePageHookContentHeading
          homePageHookContentBody
        }
    
        homePageStats {
          homePageStatsHeading
          homePageStatsSubtext
          homePageStatsImageDesktop {
            node {
              sourceUrl
            }
          }
          homePageStatsImageMobile {
            node {
              sourceUrl
            }
          }
        }
    
        gettingStarted {
          gettingStartedHeading
          gettingStartedSubtext
          gettingStartedFeatureImage {
            node {
              sourceUrl
            }
          }
          gettingStartedButtonText
        }
    
        resources {
          resourcesHeading
        }

        sharedFooter {
          footerContactHeaderText
          footerContactLinkText1
          footerContactLinkUrl1
          footerContactLinkText2
          footerContactLinkUrl2
          footerContactLinkText3
          footerContactLinkUrl3
          footerSocialHeaderText
          footerSocialFacebookUrl
          footerSocialTwitterUrl
          footerSocialInstagramUrl
          footerLegalHeaderText
          footerLegalLinkText1
          footerLegalLinkUrl1
          footerLegalLinkText2
          footerLegalLinkUrl2
        }

        sharedErrorMessaging {
          sharedAddressNotFoundErrorMessage
        }
      }

      moreResourcesArticles {
        edges {
          node {
            moreResourcesArticleGroup {
              moreResourcesArticleHeading
              moreResourcesArticleUrl
              moreResourcesArticleImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `;
};
