import React from "react";
import "./ResourcesSection.css";
import { MoreResources } from "../../common/MoreResources";
import { GetStarted } from "../../common/GetStarted";
import { HomePageConfig } from "../../lib/types/cmsConfigs";

interface ResourcesSectionProps {
  config: HomePageConfig;
}

export const ResourcesSection = ({ config }: ResourcesSectionProps) => {
  return (
    <div className="resources-section">
      <MoreResources config={config.resources} />
      <GetStarted config={config.gettingStarted} />
    </div>
  );
};
