import React from "react";
import { AccordionDisplay } from "./AccordionDisplay";
import { RoomOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import "./MeetingAccordion.css";
import { DistrictDetails } from "../../lib/types/ballotReadyData";
import { InformationUnavailableDisplay } from "./InformationUnavailableDisplay";
import { Link } from "../../common/Link";

interface MeetingAccordionProps {
  meetingInfo?: DistrictDetails;
  districtUrl?: string;
}

export const MeetingAccordion = ({
  meetingInfo,
  districtUrl,
}: MeetingAccordionProps) => {
  const hasMeetingInfo =
    meetingInfo && (meetingInfo.dateFormula || meetingInfo.addressLine1);

  if (hasMeetingInfo) {
    let updatedAtDisplayDateString;
    if (meetingInfo.meetingUpdatedAt) {
      updatedAtDisplayDateString = new Date(
        meetingInfo.meetingUpdatedAt,
      ).toLocaleDateString("en-US");
    }

    return (
      <AccordionDisplay
        title="Board Meetings"
        updated={updatedAtDisplayDateString}
      >
        <MeetingInfo meetingInfo={meetingInfo} districtUrl={districtUrl} />
      </AccordionDisplay>
    );
  }

  return (
    <AccordionDisplay title="Board Meetings">
      <InformationUnavailableDisplay districtUrl={districtUrl} />
    </AccordionDisplay>
  );
};

const MeetingInfo = ({
  meetingInfo,
  districtUrl,
}: {
  meetingInfo: DistrictDetails;
  districtUrl?: string;
}) => {
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    dateFormula,
    meetingTime,
  } = meetingInfo;
  const hasBoardMeetingLocationAvailable = addressLine1 && city && state && zip;
  const hasNextBoardMeetingDateAvailable = dateFormula;

  const roomInfo = addressLine2 ? `${addressLine2}, ` : "";
  const addressString = `${addressLine1}, ${roomInfo}${city}, ${state} ${zip}`;
  const timeInfo = meetingTime ? ` @ ${meetingTime}` : "";
  const timingString = `Meetings scheduled on the ${dateFormula}${timeInfo}`;

  return (
    <Stack direction="column" spacing={1} className="accordion-stack">
      {hasBoardMeetingLocationAvailable && (
        <>
          <div className="district-section-titles">Board Meeting Location</div>
          <Stack direction="row" spacing={1} className="meeting-location">
            <RoomOutlined className="location-icon" />
            <p>{addressString}</p>
          </Stack>
        </>
      )}

      {hasNextBoardMeetingDateAvailable && (
        <>
          <div className="meeting-schedule">
            <div
              className="district-section-titles"
              style={{ marginBottom: "0px" }}
            >
              Upcoming Meetings
            </div>
          </div>

          <Stack className="date-details" direction="column" spacing={1}>
            <p>{timingString}</p>
          </Stack>
          <p className="meeting-time-disclaimer">
            {disclaimerText(districtUrl)}
          </p>
        </>
      )}
    </Stack>
  );
};

const disclaimerText = (districtUrl?: string) => {
  return districtUrl ? (
    <span>
      Note: Please visit the{" "}
      <Link href={districtUrl} style={{ color: "var(--primary-600-main)" }}>
        <span>School Board website</span>
      </Link>{" "}
      for the exact dates
    </span>
  ) : (
    <span>Note: Please visit the School Board website for the exact dates</span>
  );
};
