export const loadDataFromGraphql = async (query: string) => {
  const base_url = process.env.REACT_APP_WP_URL as string;
  const response = await fetch(
    base_url + "?" + new URLSearchParams({ query }),
    {
      headers: { "Content-Type": "text/plain" },
      method: "GET",
    },
  );
  return await response.json();
};
