import { useState } from "react";
import { BallotReadyData } from "../lib/types/ballotReadyData";

export const useGetDistrictData = (lat: string, lng: string) => {
  const [ballotReadyData, setBallotReadyData] = useState<BallotReadyData[]>();
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "/retrieveDistrictData?lat=" +
          lat +
          "&long=" +
          lng,
      );
      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        setBallotReadyData(data);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  return { loadData, ballotReadyData, isLoading };
};
