import React from "react";
import { AddressSearch } from "../../common/AddressSearch";
import { HtmlRenderer } from "../../common/HtmlRenderer";
import { Card, CardActions } from "@mui/material";
import "./AddressLookupSection.css";
import Grid from "@mui/material/Unstable_Grid2";
import { HomePageConfig } from "../../lib/types/cmsConfigs";
import { ReactComponent as Underline } from "../../assets/Underline.svg";

type AddressLookupSectionProps = {
  config: HomePageConfig;
};

export const AddressLookupSection = ({ config }: AddressLookupSectionProps) => {
  return (
    <Card className="address-lookup-container">
      <Grid container>
        <Grid xs={12} md={5}>
          <header className="hero-header">
            <h1 className="hero-header-heading">
              <HtmlRenderer html={config.header.headingHtml} />
            </h1>
            <Underline />
          </header>

          <CardActions>
            <AddressSearch
              findAddressDataTestId="find-address-button"
              label={config.header.inputLabel}
              errorMessagingConfig={config.errorMessaging}
            ></AddressSearch>
          </CardActions>
        </Grid>
        <Grid xs={12} md={7}>
          <div className="hero-image">
            <img
              alt="logo"
              src={config.header.featureImageUrl}
              width="400px"
              height="400px"
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
