import * as React from "react";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import XQLogo from "../assets/XQ_Logo.png";
import Stack from "@mui/material/Stack";

export const HeaderNav = () => {
  return (
    <AppBar position="sticky" aria-label="Navigation" role="navigation">
      <Box sx={{ flexGrow: 1 }}>
        <div
          style={{
            alignItems: "center",
            padding: "8px 24px",
            gap: "8px",
            background: "#FFFFFF",
            color: "#000000",
            fontFamily: "Roobert",
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="navbar"
          >
            <Box>
              <Link href="/" aria-label="Back to home">
                <img
                  alt="logo"
                  src={XQLogo}
                  width="67px"
                  height="35px!important"
                />
              </Link>
            </Box>
          </Stack>
        </div>
      </Box>
    </AppBar>
  );
};
