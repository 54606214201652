import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/system/Unstable_Grid";
import "./StatisticsAndDataSection.css";
import { HomePageConfig } from "../../lib/types/cmsConfigs";
import { HtmlRenderer } from "../../common/HtmlRenderer";
// import { useMediaQuery } from "react-responsive";

interface StatisticsAndDataSectionProps {
  config: HomePageConfig;
}

export const StatisticsAndDataSection = ({
  config,
}: StatisticsAndDataSectionProps) => {
  /* const isTabletOrDesktop = useMediaQuery({
    minWidth: 1014,
  }); */

  return (
    <Stack className="stats-data-section">
      <Grid container>
        <Grid xs={12} md={8} mdOffset={2}>
          <h2>
            <HtmlRenderer html={config.stats.headingHtml} />
          </h2>
          <p>
            <HtmlRenderer html={config.stats.subtext} />
          </p>
          <div className="data-container">
            {/*isTabletOrDesktop ? (
              <img
                width={700}
                src={config.stats.imageDesktopUrl}
                alt="School Board Data & Statistics"
                className="stats-image-dekstop"
              />
            ) : (
              <img
                width={300}
                src={config.stats.imageMobileUrl}
                alt="School Board Data & Statistics"
                className="stats-image-mobile"
              />
            )*/}
          </div>
        </Grid>
      </Grid>
    </Stack>
  );
};
