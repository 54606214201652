import { ReactElement, useEffect } from "react";
import { trackPageView } from "../lib/analytics";

export const AnalyticsRoute = ({ children }: { children: ReactElement }) => {
  useEffect(() => {
    trackPageView();
  });

  return children;
};
